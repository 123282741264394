<template>
    <div class="comax-client-orders-main">
        <div class="comax-client-orders-main-title">
            <h2>מערכת הזמנות לסניף {{user?.branche || 'אנא המתן...'}}</h2>
        </div>
        <div class="comax-client-orders-main-wrapper">
            <KeepAlive>
                <component
                    @next="(new_state)=>state=new_state"
                    :is="state" 
                />
            </KeepAlive>
        
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import {user} from '../../../Methods/Utils'
export default {
    components:{
        ChooseSupplier:defineAsyncComponent(() => import('./client/ChooseSupplier.vue')),
        Catalog:defineAsyncComponent(() => import('./client/Catalog.vue')),
    },
    setup () {
        
        const state = ref('ChooseSupplier')

        return {
            user,
            state

        }
    }
}
</script>

<style  scoped>
    .comax-client-orders-main{
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column
    }
    .comax-client-orders-main-title{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .comax-client-orders-main-wrapper{
        width: 100%;
        max-width: 1200px;
        height: calc(100% - 50px);
        margin: 0 auto;
    }
</style>